import React, { useState, useEffect } from 'react'
import { FaStar, FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { makeStyles } from '@mui/styles'
import Sidebar from '../SideBar'
import Header from '../Header'
import Footer from '../../Footer'
import axios from '../../../api/axios'
import { getToken } from '../../../utils/auth'

import '../../../utils/doctor_info'
import Doctor from '../../../utils/doctor_info'

// transfor to mui
const useStyles = makeStyles({
  rating: {
    fontSize: '20px',

    float: 'right',
  },

  star: {
    cursor: 'pointer',
    color: '#ccc',
    margin: '0.2rem',
  },

  my: {
    backgroundColor: 'white',
    marginBottom: '1rem!important',
    borderRadius: '20px',
    border: '2px solid #ededed',
  },

  textreviewP: {
    fontSize: '18px',
    color: '#757575',
  },
  '@media (max-width: 768px)': {
    rating: {
      fontSize: '16px',
    },
  },

  '@media (max-width: 768px)': {
    profile: {
      marginLeft: '10px', // Adjust the margin as needed for smaller screens
    },
  },

  starFilled: {
    color: '#F4C150',
  },
})

const Reviews = () => {
  const classes = useStyles()
  const [reviews, setReviews] = useState([])
  const token = getToken

  const doc = new Doctor()

  const doctorId = doc.id

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const storedReviews = JSON.parse(localStorage.getItem('reviews'))

        if (storedReviews) {
          setReviews(storedReviews)
        }

        //64dbb1ad7c3480aef8ff9a33
        const response = await axios.get(`/api/reviews/doctor/${doctorId}`, {
          //const response = await axios.get(`/api/reviews/doctor/64dbb1ad7c3480aef8ff9a33`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        setReviews(response.data.reviews)
      } catch (error) {
        console.error('Error fetching reviews:', error)
      }
    }
    const reviews = [
      {
        rating: 5,
        review_text:
          'I had a great experience with Dr. John Doe. He is very professional and knowledgeable. I highly recommend him.',
        date: '2024-01-05T00:00:00.000Z',
      },

      {
        rating: 3,
        review_text:
          'Dr. John Doe is a good doctor. He is very professional and knowledgeable. I highly recommend him.',
        date: '2023-11-30T00:00:00.000Z',
      },
      {
        rating: 4,
        review_text:
          'Dr. John Doe is a good doctor. He is very professional and knowledgeable. I highly recommend him.',
        date: '2023-11-13T00:00:00.000Z',
      },
      {
        rating: 4,
        review_text:
          'Dr. John Doe is a good doctor. He is very professional and knowledgeable. I highly recommend him.',
        date: '2023-09-24T00:00:00.000Z',
      },
    ]
    setReviews(reviews)
    //fetchReviews()
  }, [token, doctorId])

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Reviews
                  </li>
                </ol>
              </nav>

              <h2 class="breadcrumb-title">Reviews</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9 ">
              {reviews.length === 0 ? (
                <>
                  <Container
                    className={classes.my}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 50,
                    }}
                  >
                    <Row className="mb-4">
                      <h3
                        className={classes.textreviewH}
                        style={{ textAlign: 'center' }}
                      >
                        No Reviews Available
                      </h3>
                    </Row>
                    <Row className="mb-4" style={{ textAlign: 'center' }}>
                      <img
                        src={require('../../../assets/images/reviews.png')}
                        className="me-3"
                        alt="Reviews"
                      />
                    </Row>
                  </Container>
                </>
              ) : (
                reviews.map((review, index) => (
                  <Container key={index} className={`${classes.my}`}>
                    <Row className="p-md-4 p-1">
                      <div className=" col-lg-1 col-md-2 p-0">
                        <img
                          src={require('../../../assets/images/patients/placeholder.png')}
                          roundedCircle
                          width="60"
                          height="60"
                          className={`${classes.profile} me-3`}
                        />
                      </div>
                      <div className="col-lg-11 col-md-10 p-lg-0">
                        <div className="d-flex  justify-content-between p-0">
                          <div>
                            <p className=" font-weight-bold mb-md-2 m-0">
                              Anonymous
                            </p>
                            <p className="mb-0" style={{ color: '#77c11f' }}>
                              Reviewed {review.date.toString().slice(0, 16)}
                            </p>
                            <br></br>
                          </div>
                          <div className={classes.rating}>
                            {[1, 2, 3, 4, 5].map((_, starIndex) => (
                              <span
                                key={starIndex}
                                className={` ${classes.star} ${
                                  starIndex < review.rating
                                    ? `${classes.starFilled}`
                                    : ''
                                }`}
                              >
                                <FaStar
                                  style={{
                                    color:
                                      starIndex < review.rating
                                        ? '#F4C150'
                                        : 'inherit',
                                  }}
                                />
                              </span>
                            ))}
                          </div>
                        </div>
                        <p className={`${classes.textreviewP} mb-0`}>
                          {review.review_text}
                        </p>
                      </div>
                    </Row>
                  </Container>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default Reviews
