import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import full from '../../../assets/images/doctors/FULL.png'
import { Button, CardContent } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { fetchTimeSlots, fetchNearestTimeSlot } from '../../../api/doctor'
import { FaCheck } from 'react-icons/fa'
import dayjs from 'dayjs'

const Booking = () => {
  const [selectedDate, setSelectedDate] = useState(
    dayjs(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toString())
  )
  const [availableTimeSlots, setAvailableTimings] = useState([])
  const [timeSlotData, setTimeSlotData] = useState({})
  const [times, setTimes] = useState({})
  const [timeSlotID, setTimeSlotID] = useState([])
  const [endTime, setEndTime] = useState({})
  const navigate = useNavigate()

  let availableTimeSlots2 = [
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
  ]

  const setDefaultValuesToNearestSlot = async () => {
    setSelectedDate(
      dayjs(new Date(new Date().getTime() + 65 * 60 * 60 * 1000).toString())
    )
    try {
      const nearestTimeSlot = await fetchNearestTimeSlot()
      if (nearestTimeSlot) {
        //setTimes({ [nearestTimeSlot.date]: nearestTimeSlot.start_time })
        //setTimeSlotID([nearestTimeSlot._id])
      }
    } catch (error) {
      console.error('Error fetching nearest time slot:', error)
    }
  }
  const fetchSlots = async (date) => {
    try {
      const selectedDay = dayjs(date).format('YYYY-MM-DD')
      const response = await fetchTimeSlots(selectedDay)
      const timings = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .map((timeSlot) => timeSlot.start_time)
      setAvailableTimings(timings)

      const timeSlotData = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .reduce((acc, timeSlot) => {
          acc[timeSlot.start_time] = timeSlot.end_time
          return acc
        }, {})
      setTimeSlotData(timeSlotData)

      const timeSlotID = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .map((timeSlot) => timeSlot._id)
      setTimeSlotID(timeSlotID)
    } catch (error) {
      console.error('Error fetching available time slots:', error)
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    fetchSlots(date)
  }

  const handleTimeButtonClick = (time) => {
    const endTime = timeSlotData[time]
    setEndTime(endTime)
    setTimes((prevTimes) => ({
      ...prevTimes,
      [selectedDate]: time,
    }))
    setEndTime(endTime)
  }

  const navigateToCheckout = () => {
    const queryParams = new URLSearchParams()
    queryParams.append('date', selectedDate.toISOString())
    if (selectedDate && times[selectedDate]) {
      queryParams.append('time', times[selectedDate])

      const selectedTimeSlotId =
        timeSlotID[availableTimeSlots.indexOf(times[selectedDate])]
      queryParams.append('timeSlotId', selectedTimeSlotId)

      queryParams.append('endTime', endTime)
    }

    const queryString = queryParams.toString()
    const url = `/Checkout?${queryString}`

    navigate(url)
  }
  useEffect(() => {
    setDefaultValuesToNearestSlot()
  }, [])
  useEffect(() => {
    fetchSlots(selectedDate)
  }, [selectedDate])

  return (
    <div>
      <Header />
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Book Appointment
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Book Appointment</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row align-items-start">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #ededed',
                  borderRadius: 15,
                  padding: '20px',
                }}
              >
                <CardContent>
                  {''}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ marginTop: 20, borderRadius: 5 }}>
                      <DatePicker
                        defaultValue={dayjs(selectedDate)}
                        format="DD/MM/YYYY"
                        selected={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                  </LocalizationProvider>
                </CardContent>
                <div>
                  {availableTimeSlots2.length > 0 ? (
                    availableTimeSlots2.map((time, index) => (
                      <Button
                        style={{
                          marginTop: 10,
                          marginRight: 10,
                          backgroundColor:
                            times[selectedDate] === time
                              ? '#42c0fb'
                              : '#e8e8e8',

                          color:
                            times[selectedDate] === time ? '#fff' : '#575a5d',
                          fontSize: '15px',
                          padding: 10,
                          width: 130,
                        }}
                        key={index}
                        variant="primary"
                        onClick={() => handleTimeButtonClick(time)}
                      >
                        {times[selectedDate] === time && (
                          <FaCheck color="white" style={{ marginRight: 5 }} />
                        )}{' '}
                        {time}
                      </Button>
                    ))
                  ) : (
                    <div style={{ marginLeft: '30%' }}>
                      <img
                        src={full}
                        alt="Full"
                        style={{
                          width: '70%',
                          height: '70%',
                          marginLeft: '-15%',
                        }}
                      />
                    </div>
                  )}
                </div>

                {availableTimeSlots2.length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#15558d',
                      marginTop: 10,
                      marginBottom: 30,
                      width: 150,
                    }}
                    onClick={navigateToCheckout}
                  >
                    CONTINUE
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Booking
