import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import themes from '../../../assets/themes'
import Header from '../Header'
import Footer from '../../Footer'
import { Row } from 'react-bootstrap'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
} from '@mui/material'
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from 'react-icons/fa'
import doctorimage from '../../../assets/images/doctors/doctor-thumb-02.jpg'
import Hiddenimage from '../../../assets/images/patients/hidden.png'
import Avatar from '@mui/material/Avatar'
import Rating from '@mui/material/Rating'
import DentistIcon from '../../../assets/images/icons/dentist.svg'
import StarIcon from '@mui/icons-material/Star'
import feature1 from '../../../assets/images/features/feature-01.jpg'
import feature2 from '../../../assets/images/features/feature-02.jpg'
import feature3 from '../../../assets/images/features/feature-03.jpg'
import feature4 from '../../../assets/images/features/feature-04.jpg'
import CustomIcon from '../../../assets/images/icons/close.png'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ComIcon from '../../../assets/images/icons/comments.svg'
import MoneyIcon from '../../../assets/images/icons/money.svg'
import ListIcon from '../../../assets/images/icons/circle.svg'
import ServiceIcon from '../../../assets/images/icons/services.svg'
import Sidebar from '../../Patients/SideBar'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@mui/material/MobileStepper'
import { Box } from '@mui/material'
import { fetchReviews, postReview } from '../../../api/patient'
import ErrorDialog from '../../ErrorDialog'
import { BsTwitterX } from 'react-icons/bs'

const clinics = [
  {
    name: 'Clinic 1:',
    location: 'Beirut, Al Hamra ',
    direction: (
      <>
        <a href="#" style={{ color: '#09E5AB' }}>
          Get Directions
        </a>
      </>
    ),
  },
]

const weekDays = [
  { day: 'Mon', time: '9:00 AM-11:00 Am' },
  { day: 'Tue', time: '9:00 AM-11:00 Am' },
  { day: 'Wed', time: '9:00 AM-11:00 Am' },
  { day: 'Thu', time: '9:00 AM-11:00 Am' },
  { day: 'Fri', time: '9:00 AM-11:00 Am' },
  { day: 'Sat', time: '9:00 AM-11:00 Am' },
  { day: 'Sun', time: '9:00 AM-11:00 Am' },
]

const DoctorProfile = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isBookmarked, setIsBookmarked] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const location = useLocation()
  const { doctorData, doctorRating } = location.state

  function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked)
    setOpenAlert(true)
  }
  const handleAlertClose = () => {
    setOpenAlert(false)
  }
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const features = [feature1, feature2, feature3, feature4]

  const handleOpenModal = (index) => {
    setActiveStep(index)
    setOpenModal(true)
  }

  const gallery = doctorData.gallery

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const nextStep = prevActiveStep + 1
      const newStep = nextStep >= gallery.length ? 0 : nextStep
      //setSelectedFeature(features[newStep])
      return newStep
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newStep =
        prevActiveStep - 1 < 0 ? gallery.length - 1 : prevActiveStep - 1
      return newStep
    })
  }
  const handleCloseErrorDialog = () => {
    setError(false)
  }

  const navigate = useNavigate()

  const handleBookAppointmentClick = (doctorData) => {
    navigate('/PatientBooking', { state: { doctorData } })
  }

  const [reviews, setReviews] = useState([])
  const [newReviewText, setNewReviewText] = useState('')
  const [newReviewRating, setNewReviewRating] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reviewsData = await fetchReviews()
        setReviews(reviewsData)
      } catch (error) {
        console.log('Error fetching patient data', error)
      }
    }

    fetchData()
  }, [])

  const handlePostReview = async () => {
    try {
      const newReview = await postReview({
        rating: newReviewRating,
        review_text: newReviewText,
      })
      if (newReview.success) {
        const updatedReviews = [...reviews, newReview]
        setReviews(updatedReviews)

        setNewReviewText('')
        setNewReviewRating(0)
      } else {
        setError(true)
        setErrorMessage(newReview.response.data.message)
      }
    } catch (error) {
      console.error('Error posting review', error)
    }
  }

  const handleViewBusinessHoursClick = () => {
    setIsDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const isMobile = screenWidth < 990
  return (
    <div style={{ fontFamily: themes.typography.fontFamily }}>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    View Profile
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Doctor Profile</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}

      {/* Body Content */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card
                sx={{
                  width: '100%',
                  maxWidth: '1671px',
                  height: 'auto',
                  marginBottom: '20px',
                  padding: '25px',
                  flexDirection: isMobile ? 'column' : 'row',
                  borderRadius: '6px',
                  border: '1px solid #F0F0F0',
                  background: '#FFF',
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={3} md={2} lg={2}>
                    <CardMedia
                      component="img"
                      alt="Doctor Image"
                      height="160px"
                      width="160px"
                      src={doctorData.profile_image}
                      sx={{
                        borderRadius: '15px',
                        objectPosition: '20%',
                        ...(isMobile
                          ? { marginBottom: '20px' }
                          : { marginLeft: '10px' }),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={8} lg={7}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`${doctorData.title} ${doctorData.firstname} ${doctorData.lastname}`}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ color: 'grey' }}>
                        {doctorData.about}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '15px',
                      }}
                    >
                      <img
                        src={DentistIcon}
                        alt="Dentist Icon"
                        width="20px"
                        height="20px"
                        style={{ marginRight: '10px' }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: themes.colors.Lightblue }}
                      >
                        {doctorData.services.join(', ')}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <Rating
                        name="half-rating-read"
                        value={doctorRating}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                    <div style={{ display: 'flex', marginTop: '15px' }}>
                      {gallery.map((feature, index) => (
                        <img
                          key={index}
                          src={feature}
                          alt={`Feature ${index + 1}`}
                          width="40px"
                          height="40px"
                          style={{
                            marginRight: '9px',
                            cursor: 'pointer',
                            borderRadius: '4px',
                          }}
                          onClick={() => handleOpenModal(index)}
                        />
                      ))}

                      <Dialog
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        maxWidth="md"
                        fullWidth={false}
                      >
                        <SwipeableViews index={activeStep} enableMouseEvents>
                          {gallery.map((feature, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <img src={feature} alt={`Feature ${index + 1}`} />
                            </Box>
                          ))}
                        </SwipeableViews>
                        <MobileStepper
                          steps={gallery.length}
                          position="static"
                          activeStep={activeStep}
                          nextButton={
                            <Button
                              size="small"
                              onClick={handleNext}
                              disabled={activeStep === gallery.length - 1}
                            >
                              <KeyboardArrowRight />
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              onClick={handleBack}
                              disabled={activeStep === 0}
                            >
                              <KeyboardArrowLeft />
                            </Button>
                          }
                        />
                      </Dialog>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      {doctorData.specializations.map(
                        (specialization, serviceIndex) => (
                          <Typography
                            key={serviceIndex}
                            variant="body2"
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              color: 'grey',
                              width: '100px',
                              border: '1px solid #09E5AB',
                              borderRadius: '5px',
                              padding: '5px',
                              marginRight: '10px',
                            }}
                          >
                            {capitalizeFirstLetter(specialization)}
                          </Typography>
                        )
                      )}
                      {/* <Typography
                        variant="body2"
                        sx={{
                          color: 'grey',
                          border: '1px solid #09E5AB',
                          borderRadius: '5px',
                          padding: '5px',
                          marginRight: '10px',
                        }}
                      >
                        Dental Fillings
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'grey',
                          border: '1px solid #09E5AB',
                          borderRadius: '5px',
                          padding: '5px',
                        }}
                      >
                        Teeth Whitening
                      </Typography> */}
                    </div>
                  </Grid>
                  <Grid item xs={3} md={2} lg={3}>
                    <Row>
                      <img
                        src={ComIcon}
                        alt="Your Icon"
                        width="20px"
                        height="20px"
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: 'grey', marginLeft: '10px' }}
                      >
                        1 Feedback
                      </Typography>
                    </Row>
                    <Row>
                      <img
                        src={MoneyIcon}
                        alt="Money Icon"
                        width="20px"
                        height="20px"
                      />
                      <Typography
                        variant="body2"
                        sx={{ color: 'grey', marginLeft: '10px' }}
                      >
                        {doctorData.consultation_fee} $ per hour
                      </Typography>
                    </Row>
                    <Row>
                      <a href={''}>
                        <FaFacebookF className="mr-3" color="grey" />
                      </a>
                      <a href={''}>
                        <BsTwitterX className="mr-3" color="grey" />
                      </a>
                      <a href={''}>
                        <FaLinkedinIn className="mr-3" color="grey" />
                      </a>
                      <a href={''}>
                        <FaInstagram className="mr-3" color="grey" />
                      </a>
                    </Row>

                    <Row>
                      <button
                        style={{
                          backgroundColor: themes.colors.Lightblue,
                          color: 'white',
                          border: 'none',
                          marginTop: '60px',
                          padding: '10px 20px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleBookAppointmentClick(doctorData)}
                      >
                        Book Now
                      </button>
                      <Dialog
                        open={selectedFeature !== null}
                        onClose={() => setSelectedFeature(null)}
                      >
                        <img src={selectedFeature} alt="Selected Feature" />
                      </Dialog>

                      <div>
                        <Button
                          style={{
                            color: isBookmarked
                              ? themes.colors.primary
                              : themes.colors.lightGrey,
                            marginTop: '60px',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={handleBookmarkClick}
                        >
                          <BookmarkIcon />
                        </Button>

                        <Snackbar
                          open={openAlert}
                          autoHideDuration={5000}
                          onClose={handleAlertClose}
                          message="Added to favorites"
                          action={
                            <IconButton
                              size="small"
                              aria-label="close"
                              color="inherit"
                              onClick={handleAlertClose}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          }
                          style={{
                            position: 'fixed',
                            left: '50%',
                            top: '60%',
                            transform: 'translate(-40%, -30%)',
                          }}
                        />
                      </div>
                      <Dialog
                        open={selectedFeature !== null}
                        onClose={() => setSelectedFeature(null)}
                      >
                        <img src={selectedFeature} alt="Selected Feature" />
                      </Dialog>
                    </Row>
                  </Grid>
                </Grid>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  maxWidth: '1671px',
                  height: 'auto',
                  marginBottom: '20px',
                  padding: '25px',
                  flexDirection: isMobile ? 'column' : 'row',
                  borderRadius: '6px',
                  border: '1px solid #F0F0F0',
                  background: '#FFF',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '30px 0',
                      width: isMobile ? '100%' : '1296px',
                      overflowX: isMobile ? 'auto' : 'visible',
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        width: '100%',
                        flexDirection: isMobile ? 'column' : 'row',
                      }}
                    >
                      <Tab
                        label="Overview"
                        sx={{
                          fontWeight: activeTab === 0 ? 'bold' : 'normal',
                          marginRight: isMobile ? '0' : '250px',
                          marginLeft: isMobile ? '0' : '70px',
                          marginBottom: isMobile ? '10px' : '0', // Add margin at the bottom on small screens
                        }}
                      />
                      <Tab
                        label="Locations"
                        sx={{
                          fontWeight: activeTab === 1 ? 'bold' : 'normal',
                          marginRight: isMobile ? '0' : '300px',
                          marginBottom: isMobile ? '10px' : '0', // Add margin at the bottom on small screens
                        }}
                      />
                      <Tab
                        label="Reviews"
                        sx={{
                          fontWeight: activeTab === 2 ? 'bold' : 'normal',
                          marginRight: isMobile ? '0' : '250px',
                          marginBottom: isMobile ? '10px' : '0', // Add margin at the bottom on small screens
                        }}
                      />
                    </Tabs>
                  </div>
                  {activeTab === 0 && (
                    <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginTop: '30px' }}
                      >
                        About Me
                      </Typography>
                      <Typography sx={{ fontsize: '15px' }}>
                        {doctorData.about}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginTop: '30px' }}
                      >
                        Education
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                        }}
                      >
                        {doctorData.qualifications.map(
                          (qualification, index) => (
                            <div key={index}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  src={ListIcon}
                                  alt="List Icon"
                                  width="15px"
                                  height="15px"
                                />
                                <Typography
                                  sx={{
                                    fontSize: '17px',
                                    fontWeight: '500',
                                    marginLeft: '20px',
                                    marginTop: '-5px',
                                  }}
                                >
                                  {qualification.institution}
                                </Typography>
                              </div>
                              <Typography
                                sx={{
                                  fontSize: '15px',
                                  color: 'grey',
                                  marginLeft: '34px',
                                }}
                              >
                                {qualification.major}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '15px',
                                  color: 'grey',
                                  marginLeft: '34px',
                                }}
                              >
                                {`${qualification.start_year}-${qualification.end_year}`}
                              </Typography>
                            </div>
                          )
                        )}
                      </div>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginTop: '30px' }}
                      >
                        Work & Experience
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                        }}
                      >
                        {doctorData.experience.map((experiences, index) => (
                          <div key={index}>
                            <div style={{ display: 'flex' }}>
                              <img
                                src={ListIcon}
                                alt="List Icon"
                                width="15px"
                                height="15px"
                              />
                              <Typography
                                sx={{
                                  fontSize: '17px',
                                  fontWeight: '500',
                                  marginLeft: '20px',
                                  marginTop: '-5px',
                                }}
                              >
                                {experiences.institution}
                              </Typography>
                            </div>
                            <Typography
                              sx={{
                                fontSize: '15px',
                                color: 'grey',
                                marginLeft: '34px',
                              }}
                            >
                              {`${experiences.start_year}-${experiences.end_year}`}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginTop: '30px' }}
                      >
                        Awards
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '15px',
                        }}
                      >
                        {doctorData.awards.map((award, index) => (
                          <div key={index}>
                            <div style={{ display: 'flex' }}>
                              <img
                                src={ListIcon}
                                alt="List Icon"
                                width="15px"
                                height="15px"
                              />
                              <Typography
                                sx={{
                                  fontSize: '17px',
                                  fontWeight: '500',
                                  marginLeft: '20px',
                                  marginTop: '-5px',
                                  color: themes.colors.Lightblue,
                                }}
                              >
                                {`${new Date(
                                  award.year,
                                  award.month - 1,
                                  1
                                ).toLocaleString('en-US', { month: 'long' })} ${
                                  award.year
                                }`}{' '}
                              </Typography>
                            </div>
                            <Typography
                              sx={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                                marginLeft: '34px',
                                marginTop: '-5px',
                              }}
                            >
                              {award.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '17px',
                                color: 'grey',
                                marginLeft: '34px',
                              }}
                            >
                              {award.description}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 'bold', marginTop: '30px' }}
                      >
                        Services
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {doctorData.services.map((service, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '15px',
                              marginRight: '20px',
                            }}
                          >
                            <img
                              src={ServiceIcon}
                              alt="List Icon"
                              width="15px"
                              height="15px"
                            />
                            <Typography
                              sx={{
                                fontSize: '17px',
                                fontWeight: '500',
                              }}
                            >
                              {service}
                            </Typography>
                          </div>
                        ))}{' '}
                      </div>
                      <div>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 'bold', marginTop: '30px' }}
                        >
                          Specializations
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          {doctorData.specializations.map(
                            (specialization, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '15px',
                                  marginRight: '20px',
                                }}
                              >
                                <img
                                  src={ServiceIcon}
                                  alt="List Icon"
                                  width="15px"
                                  height="15px"
                                />
                                <Typography
                                  sx={{
                                    fontSize: '17px',
                                    fontWeight: '500',
                                  }}
                                >
                                  {specialization}
                                </Typography>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <Card
                      sx={{
                        width: '100%',
                        maxWidth: '1671px',
                        height: 'auto',
                        marginBottom: '20px',
                        borderRadius: '6px',
                        border: '1px solid #F0F0F0',
                        background: '#FFF',
                      }}
                    >
                      <CardContent
                        sx={{
                          diplay: 'flex',
                          flexDirection: isMobile ? 'column' : undefined,
                        }}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={4} md={2} lg={2}>
                            <CardMedia
                              component="img"
                              alt="Doctor Image"
                              height="160px"
                              width="160px"
                              src={doctorData.gallery[0]}
                              sx={{
                                borderRadius: '15px',
                                ...(isMobile
                                  ? { marginBottom: '20px' }
                                  : { marginLeft: '10px' }),
                              }}
                            />
                          </Grid>
                          {doctorData.gallery.map((clinic, index) => (
                            <Grid item xs={6} md={8} lg={7}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {`${doctorData.title} ${doctorData.firstname} ${doctorData.lastname}`}
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  marginTop: '15px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: isMobile
                                      ? 'column'
                                      : undefined,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <LocationOnIcon />
                                    <Typography
                                      sx={{
                                        color: '#757575',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {doctorData.location_name
                                        .split(', ')
                                        .slice(0, 3)
                                        .join(', ')}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <Typography
                                sx={{
                                  marginLeft: '22.5px',
                                }}
                              >
                                <a
                                  href={`https://maps.google.com/maps?q=${doctorData.location}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Get Directions
                                </a>
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  marginTop: isMobile ? '10px' : '30px',
                                }}
                              >
                                {doctorData.gallery.map((feature, index) => (
                                  <img
                                    key={index}
                                    src={feature}
                                    alt={`Feature ${index + 1}`}
                                    width="40px"
                                    height="40px"
                                    style={{
                                      marginRight: '9px',
                                      cursor: 'pointer',
                                      borderRadius: '4px',
                                    }}
                                    onClick={() => handleOpenModal(index)}
                                  />
                                ))}

                                {/* Dialog and Swipeable Views */}
                                <Dialog
                                  open={openModal}
                                  onClose={() => setOpenModal(false)}
                                  maxWidth="md"
                                  fullWidth={false}
                                >
                                  <SwipeableViews
                                    index={activeStep}
                                    enableMouseEvents
                                  >
                                    {doctorData.gallery.map(
                                      (feature, index) => (
                                        <Box
                                          key={index}
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <img
                                            src={feature}
                                            alt={`Feature ${index + 1}`}
                                          />
                                        </Box>
                                      )
                                    )}
                                  </SwipeableViews>
                                  <MobileStepper
                                    steps={doctorData.gallery.length}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                      <Button size="small" onClick={handleNext}>
                                        <KeyboardArrowRight />
                                      </Button>
                                    }
                                    backButton={
                                      <Button size="small" onClick={handleBack}>
                                        <KeyboardArrowLeft />
                                      </Button>
                                    }
                                  />
                                </Dialog>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                        <div
                          style={{
                            marginTop: isMobile ? undefined : '-120px',
                            marginLeft: isMobile
                              ? undefined
                              : screenWidth > 1100 && screenWidth < 1200
                              ? '400px'
                              : screenWidth > 1200 && screenWidth < 1300
                              ? '550px'
                              : screenWidth > 900 && screenWidth < 1100
                              ? '350px'
                              : '680px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: isMobile
                              ? 'center'
                              : screenWidth > 900 && screenWidth < 980
                              ? 'center'
                              : undefined,
                            alignItems: isMobile ? 'center' : undefined,
                          }}
                        >
                          <button
                            onClick={handleViewBusinessHoursClick}
                            style={{
                              width: '200px',
                              height: '45px',
                              border: '2px solid #20C0F3',
                              borderRadius: '4px',
                              color: '#20C0F3',
                              backgroundColor: '#fff',
                            }}
                          >
                            View Business Hours
                          </button>
                          <button
                            onClick={() =>
                              handleBookAppointmentClick(doctorData)
                            }
                            style={{
                              marginTop: '15px',
                              width: '200px',
                              height: '45px',
                              border: '2px solid #20C0F3',
                              color: '#fff',
                              borderRadius: '4px',
                              backgroundColor: '#20C0F3',
                              padding: '10px',
                            }}
                          >
                            Book Appointment
                          </button>
                        </div>
                        <Dialog
                          open={isDialogOpen}
                          onClose={handleCloseDialog}
                          aria-labelledby="dialog-title"
                          style={{
                            marginBottom: isMobile ? '400px' : undefined,
                          }}
                        >
                          <DialogContent style={{ padding: '0' }}>
                            <img
                              src={CustomIcon}
                              width="24px"
                              height="24px"
                              onClick={handleCloseDialog}
                              alt=""
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                marginTop: '20px',
                                marginRight: '20px',
                                cursor: 'pointer',
                              }}
                            />
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Day</TableCell>
                                    <TableCell>Time</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {weekDays.map((week, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{week.day}</TableCell>
                                      <TableCell>{week.time}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  )}
                </div>

                {activeTab === 2 && (
                  <>
                    {reviews.map((card, index) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Card
                          key={index}
                          variant="outlined"
                          sx={{
                            width: screenWidth <= 1204 ? '100%' : '805px',
                            height: '138px',
                            border: '1px solid grey',
                            borderRadius: 4,
                            marginTop: '20px',
                          }}
                        >
                          <CardContent>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Avatar
                                src={card.profile}
                                alt="Hidden"
                                sx={{
                                  width: 48,
                                  height: 48,
                                  borderRadius: '50%',
                                }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: '16px',
                                    marginRight: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {card.profile}
                                  </Typography>
                                  <div
                                    style={{
                                      marginLeft: isMobile
                                        ? '20px'
                                        : screenWidth > 1100 &&
                                          screenWidth < 1200
                                        ? '300px'
                                        : screenWidth > 1200 &&
                                          screenWidth < 1300
                                        ? '500px'
                                        : screenWidth > 1200
                                        ? '550px'
                                        : screenWidth > 900 &&
                                          screenWidth < 1100
                                        ? '250px'
                                        : '180px',

                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: isMobile
                                        ? 'center'
                                        : screenWidth > 900 && screenWidth < 980
                                        ? 'center'
                                        : undefined,
                                      alignItems: isMobile ? '40px' : undefined,
                                    }}
                                  >
                                    <Rating
                                      name="rating"
                                      value={card.rating}
                                      readOnly
                                      size="small"
                                    />
                                  </div>
                                </div>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  sx={{
                                    color: 'grey',
                                    fontSize: '13px',
                                    marginLeft: '12px',
                                  }}
                                >
                                  Reviewed{' '}
                                  {
                                    card.date
                                      .toString()
                                      .slice(0, 16)
                                      .split('T')[0]
                                  }
                                  ,
                                  {
                                    card.date
                                      .toString()
                                      .slice(0, 16)
                                      .split('T')[1]
                                  }
                                </Typography>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  sx={{
                                    color: 'grey',
                                    fontSize: '13px',
                                    marginLeft: isMobile ? '12px' : '12px',
                                    marginTop: isMobile ? '0' : '14px',
                                  }}
                                >
                                  {card.review_text}
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    ))}

                    <Typography
                      sx={{
                        marginTop: '24px',
                        color: themes.colors.Lightblue,
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginRight: '140px',
                      }}
                    >
                      Load More
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column', // To stack cards vertically
                        alignItems: 'center', // Center horizontally
                      }}
                    >
                      <Card
                        sx={{
                          width: screenWidth <= 1204 ? '100%' : '805px',
                          height: screenWidth <= 1204 ? '100%' : '232px',
                          border: '1px solid grey',
                          borderRadius: 4,
                          marginTop: '24px',
                        }}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                marginBottom: isMobile ? '10px' : '0',
                              }}
                            >
                              Write a Review for this doctor
                            </Typography>
                            {isMobile && (
                              <Stack
                                spacing={1}
                                sx={{ marginLeft: '0', marginTop: '12px' }}
                              >
                                <Rating
                                  name="half-rating"
                                  defaultValue={0}
                                  precision={1}
                                  value={newReviewRating}
                                  onChange={(event, newValue) => {
                                    setNewReviewRating(newValue)
                                  }}
                                />
                              </Stack>
                            )}
                            {!isMobile && (
                              <Stack
                                spacing={1}
                                sx={{ marginLeft: '350px', marginTop: '0' }}
                              >
                                <Rating
                                  name="half-rating"
                                  defaultValue={0}
                                  precision={1}
                                />
                              </Stack>
                            )}
                          </div>
                          <input
                            type="text"
                            placeholder="This is a text field to write your review"
                            value={newReviewText}
                            onChange={(e) => setNewReviewText(e.target.value)}
                            style={{
                              width: '100%',
                              height: '100px',
                              border: '1px solid lightgrey',
                              marginTop: '20px',
                              padding: '8px',
                            }}
                          />
                          <button
                            onClick={handlePostReview}
                            style={{
                              backgroundColor: themes.colors.Lightblue,
                              color: 'white',
                              border: 'none',
                              padding: '10px 20px',
                              cursor: 'pointer',

                              marginTop: '12px',
                              borderRadius: 4,
                              height: '30px',
                              width: '250px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            Submit Review
                          </button>
                        </CardContent>
                      </Card>
                    </div>
                    <ErrorDialog
                      open={error}
                      onClose={handleCloseErrorDialog}
                      errorMessage={errorMessage}
                    />
                  </>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Body Content */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default DoctorProfile
