import React, { useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete'
import themes from '../../../assets/themes'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
const MedicalRecords = () => {
  const colors = themes.colors
  const [appointments, setAppointments] = useState([])

  // Simulate an API call to fetch appointments
  const fetchAppointments = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            Patient: 'Richard Wilson',
            date: '14 Nov 2022',
            time: '9:30am',
            description: 'Benzaxapine Croplex',
            uploadedBy: 'Your Self',
          },
          {
            Patient: 'Richard Wilson',
            date: '14 Nov 2022',
            time: '9:30am',
            description: 'Benzaxapine Croplex',
            uploadedBy: 'Dr. John Doe',
          },
          {
            Patient: 'Richard Wilson',
            date: '14 Nov 2022',
            time: '9:30am',
            description: 'Benzaxapine Croplex',
            uploadedBy: 'Your Self',
          },
          // ... more Records
        ])
      }, 2000)
    })
  }

  // Fetch appointments when the component mounts
  useEffect(() => {
    fetchAppointments().then((data) => {
      setAppointments(data)
    })
  }, [])

  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Medical Record
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Medical Record</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div style={{ marginleft: 30, width: '70%' }}>
              <div style={{ border: '1px solid #ededed' }}>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <h3 style={{ color: 'black' }}>Medical Records</h3>
                  <hr
                    style={{
                      borderBottom: '5px solid #15558d',
                      textAlign: 'center',
                    }}
                  />
                </div>
                <Button
                  variant="primary"
                  style={{
                    borderRadius: '20px',
                    float: 'right',
                    backgroundColor: colors.primary,

                    margin: '0 10px 20px 0',
                    fontWeight: 'bolder',
                  }}
                >
                  Add Medical Record
                </Button>
                <div className="col-md-12 col-lg-12 col-xl-12">
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Attachment</th>
                        <th>Uploaded By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff', border: '0' }}>
                      {appointments.map((appointment, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="d-flex align-items-center">
                            {appointment.Patient}
                          </td>
                          <td>
                            {appointment.date}
                            <br />
                            <span className="text-primary">
                              {appointment.time}
                            </span>
                          </td>
                          <td>{appointment.description}</td>
                          <td>
                            <button
                              style={{
                                border: '0px',
                                borderRadius: '5px',
                                backgroundColor: '#43a047',
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: 'white' }}
                              />
                            </button>
                          </td>

                          <td>{appointment.uploadedBy}</td>
                          <td>
                            <button
                              style={{
                                border: '0px',
                                borderRadius: '5px',
                                backgroundColor: '#ffcdd2',
                              }}
                            >
                              <DeleteIcon sx={{ color: '#e53935' }} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MedicalRecords
