import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom' // Import NavLink
import './style.css'
import {
  FaColumns,
  FaEdit,
  FaHourglassStart,
  FaStopwatch,
  FaStar,
  FaBed,
  FaCalendar,
} from 'react-icons/fa'
import Doctor from '../../../utils/doctor_info'
import { logout } from '../../../utils/logout'

function Sidebar() {
  const doc = new Doctor()
  let profilePhoto =
    'https://staging-tabiby.hexapi.tech/static/media/doctor-02.b4edc02b.jpg'
  profilePhoto === null || profilePhoto === ''
    ? (profilePhoto =
        'https://staging-tabiby.hexapi.tech/static/media/doctor-02.b4edc02b.jpg')
    : //'https://tabiby.hexapi.tech/images/doctors/placeholder.png')
      (profilePhoto = profilePhoto)
  const doctorTitle = doc.title
  const doctorName = doc.firstname + ' ' + doc.lastname
  const displayedName = doctorTitle + ' ' + doctorName
  const doctorSpeciality = doc.speciality

  const [activePage, setActivePage] = useState('/Dashboard')

  const handleNavigation = (page) => {
    setActivePage(page)
  }
  useEffect(() => {
    handleNavigation(window.location.pathname)
  }, [activePage])

  const handleLogout = () => {
    logout()
    // You can add additional logic here, such as redirecting to the sign-in page
  }
  return (
    <div class="profile-sidebar">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="#" class="booking-doc-img">
            <img src={profilePhoto} alt="Doctor" />
          </a>
          <div class="profile-det-info">
            <h3 style={{ fontWeight: 'bolder', fontSize: 24 }}>
              {displayedName}
            </h3>

            <div class="patient-details">
              <h5 class="mb-0">{'Your Speciality Here'}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-widget">
        <nav className="dashboard-menu">
          <ul>
            <li className={activePage === '/Dashboard' ? 'active' : ''}>
              <NavLink to="/Dashboard">
                <FaColumns />
                <span> &nbsp;&nbsp;Dashboard</span>
              </NavLink>
            </li>
            <li className={activePage === '/AddAppointment' ? 'active' : ''}>
              <NavLink to="/AddAppointment">
                <FaEdit />
                <span> &nbsp;&nbsp;Add Appointment</span>
              </NavLink>
            </li>
            <li className={activePage === '/PatientList' ? 'active' : ''}>
              <NavLink to="/PatientList">
                <FaBed />
                <span> &nbsp;&nbsp;My Patients</span>
              </NavLink>
            </li>
            <li className={activePage === '/OpeningHours' ? 'active' : ''}>
              <NavLink to="/OpeningHours">
                <FaHourglassStart />
                <span> &nbsp;&nbsp;Opening Hours</span>
              </NavLink>
            </li>
            <li className={activePage === '/availableTimings' ? 'active' : ''}>
              <NavLink to="/availableTimings">
                <FaStopwatch />
                <span> &nbsp;&nbsp;Available Timings</span>
              </NavLink>
            </li>
            <li className={activePage === '/DoctorCalendar' ? 'active' : ''}>
              <NavLink to="/DoctorCalendar">
                <FaCalendar />
                <span> &nbsp;&nbsp;Calendar</span>
              </NavLink>
            </li>
            <li className={activePage === '/reviews' ? 'active' : ''}>
              <NavLink to="/reviews">
                <FaStar />
                <span> &nbsp;&nbsp;Reviews</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
