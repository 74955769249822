import React, { useState, useEffect } from 'react'
import '../../../App.css'
import Header from '../Header'
import Sidebar from '../../Doctors/SideBar'
import Footer from '../../Footer'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import {
  getDayAppointments,
  getWeekAppointments,
  getMonthAppointments,
} from '../../../api/doctor'

const localizer = momentLocalizer(moment)
const DoctorCalendar = () => {
  const [events, setEvents] = useState([])
  const [selectedView, setSelectedView] = useState('month')
  const [selectedDate, setSelectedDate] = useState(new Date())

  const fetchAppointments = async (date, view) => {
    try {
      let formattedDate = date

      if (view === 'day') {
        formattedDate = moment(date).format('YYYY-MM-DD')
      } else if (view === 'week') {
        formattedDate = moment(date).format('YYYY-MM-DD')
      } else if (view === 'month') {
        formattedDate = moment(date).format('YYYY/MM')
      }
      let fetchedAppointments = []

      if (view === 'day') {
        fetchedAppointments = await getDayAppointments(formattedDate)
      } else if (view === 'week') {
        fetchedAppointments = await getWeekAppointments(formattedDate)
      } else if (view === 'month') {
        fetchedAppointments = await getMonthAppointments(formattedDate)
      }
      const transformedAppointments = fetchedAppointments.map((appointment) => {
        const appointmentDate = new Date(appointment.appointment_date)
        const startHour = appointment.start_hour.split(':')
        const endHour = appointment.end_hour.split(':')

        return {
          start: new Date(
            appointmentDate.getFullYear(),
            appointmentDate.getMonth(),
            appointmentDate.getDate(),
            parseInt(startHour[0], 10),
            parseInt(startHour[1], 10)
          ),
          end: new Date(
            appointmentDate.getFullYear(),
            appointmentDate.getMonth(),
            appointmentDate.getDate(),
            parseInt(endHour[0], 10),
            parseInt(endHour[1], 10)
          ),
          title: `${appointment.patient_name} ${appointment.patient_family}`,
        }
      })
      const mockedAppointments = [
        {
          start: new Date(2024, 0, 24, 10, 0),
          end: new Date(2024, 0, 24, 10, 30),
          title: 'Patient 1',
        },
        {
          start: new Date(2024, 0, 24, 10, 30),
          end: new Date(2024, 0, 24, 11, 0),
          title: 'Patient 2',
        },
        {
          start: new Date(2024, 0, 20, 12, 0),
          end: new Date(2024, 0, 20, 12, 30),
          title: 'Patient 3',
        },
        {
          start: new Date(2024, 1, 1, 13, 0),
          end: new Date(2024, 1, 1, 14, 0),
          title: 'Patient 4',
        },
        {
          start: new Date(2024, 0, 15, 14, 0),
          end: new Date(2024, 0, 15, 14, 30),
          title: 'Patient 5',
        },
        {
          start: new Date(2024, 0, 18, 15, 0),
          end: new Date(2024, 0, 18, 15, 30),
          title: 'Patient 6',
        },
        {
          start: new Date(2024, 1, 1, 16, 40),
          end: new Date(2024, 1, 1, 17, 0),
          title: 'Patient 7',
        },
        //Generate same mocked data as above with same titles but different dates
        {
          start: new Date(2024, 1, 12, 10, 0),
          end: new Date(2024, 1, 12, 11, 30),
          title: 'Patient 1',
        },
        {
          start: new Date(2024, 1, 13, 9, 0),
          end: new Date(2024, 1, 13, 11, 0),
          title: 'Patient 2',
        },
        {
          start: new Date(2024, 1, 22, 10, 0),
          end: new Date(2024, 1, 22, 11, 30),
          title: 'Patient 3',
        },
        {
          start: new Date(2024, 1, 7, 10, 0),
          end: new Date(2024, 1, 7, 10, 30),
          title: 'Patient 4',
        },
        {
          start: new Date(2024, 2, 12, 10, 0),
          end: new Date(2024, 2, 12, 11, 30),
          title: 'Patient 5',
        },
        {
          start: new Date(2024, 2, 13, 9, 0),
          end: new Date(2024, 2, 13, 11, 0),
          title: 'Patient 6',
        },
        {
          start: new Date(2024, 2, 15, 10, 0),
          end: new Date(2024, 2, 15, 11, 30),
          title: 'Patient 7',
        },
        {
          start: new Date(2024, 3, 27, 7, 0),
          end: new Date(2024, 3, 27, 10, 40),
          title: 'Patient 8',
        },
        {
          start: new Date(2024, 3, 12, 10, 0),
          end: new Date(2024, 3, 12, 11, 30),
          title: 'Patient 9',
        },
      ]
      setEvents(mockedAppointments)
    } catch (error) {}
  }

  useEffect(() => {
    fetchAppointments(selectedDate, selectedView)
  }, [selectedDate, selectedView])

  const handleView = (view) => {
    setSelectedView(view)
  }

  const handleNavigate = (date, view) => {
    setSelectedDate(date)
    handleView(view)
  }
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Calendar
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">My Calendar</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          {/* Page Content Should be Added here inside this div */}
          <div className="row">
            {/* Add Here your Page Content (Table,Image,...) */}
            {/*  */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div className="col-lg-8 col-xl-9 bg-white p-3">
              <Calendar
                localizer={localizer}
                events={events}
                //startAccessor="start"
                //endAccessor="end"
                min={new Date(2024, 0, 1, 8, 0)} // Set the minimum time to 8:00 AM
                max={new Date(2024, 0, 1, 22, 0)} // Set the maximum time to 10:00 PM
                // titleAccessor={`${e.title} `}
                titleAccessor={function (e) {
                  return `
                  ${e.start.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })} 
                  ${e.title}`
                }}
                views={['month', 'week', 'day']}
                defaultView="week"
                hourViewInterval={1}
                onView={handleView}
                onNavigate={handleNavigate}
                style={{ height: 500 }}
                eventPropGetter={(event) => {
                  const backgroundColor = '#15558d'
                  return { style: { backgroundColor } }
                }}
              />
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default DoctorCalendar
