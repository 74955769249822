import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import React, { useState, useEffect } from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { Table, Button } from 'react-bootstrap'
import { List, ListItem, Grid, Container, colors } from '@mui/material'
import MButton from '@mui/material/Button'
import { FaTrash } from 'react-icons/fa'
import doctorImg from '../../../assets/images/patients/patient1.jpg'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { FaDownload } from 'react-icons/fa'
import theme from '../../../assets/themes'
const useStyles = makeStyles((theme) => ({
  editButton: {
    color: '#537B35',
    backgroundColor: '#d9e9cf',
    border: 'none',
  },
  cancelButton: {
    color: '#ED1B2E',
    backgroundColor: '#f4d4d6',
    border: 'none',
  },
  deleteButton: {
    color: '#ED1B2E',
    backgroundColor: '#f4d4d6',
    border: 'none',
  },
  rowGap3: {
    rowGap: '1rem',
  },
  stripedRow: {
    backgroundColor: '#fff !important',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important', // Use Material-UI's background color
    },
    '&:last-child': {
      borderBottom: '1px solid #ededed', // Border for the last row
    },
  },
  status: {
    width: 'fit-content',
    height: '25px',
    borderRadius: '5px',
    margin: 'auto',
    display: 'block',
    padding: '0 0.25rem',
  },
  success: {
    color: '#537B35',
    background: '#d9e9cf',
    border: '0',
  },
  pending: {
    color: '#ECB731',
    background: '#f3e9cd',
  },
  danger: {
    color: '#ED1B2E',
    background: '#f4d4d6',
    border: '0',
  },
}))

const PatientProfile = () => {
  const classes = useStyles()
  //main
  const [appointments, setAppointments] = useState([])

  // Simulate an API call
  const fetchAppointments = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            doctor: 'Doctor Name',
            note: 'Dentist',
            date: '14 Nov 2023',
            date2: '29 Oct 2023',
            date3: '29 Oct 2022',
            time: '9:30am',
            status: 'Attended',
          },
          {
            doctor: 'Doctor Name',
            note: 'Dentist',
            date: '10 Sep 2023',
            date2: '17 Sep 2023',
            date3: '17 Sep 2023',
            time: '14:10am',
            status: 'Absent',
          },
          {
            doctor: 'Doctor Name',
            note: 'Dentist',
            date: '9 Sep 2023',
            date2: '5 Sep 2023',
            date3: '14 Nov 2022',
            time: '8:30am',
            status: 'Canceled',
          },

          // ... more appointments
        ])
      }, 1000)
    })
  }
  //main
  useEffect(() => {
    fetchAppointments().then((data) => {
      setAppointments(data)
    })
  }, [])
  const [patientImage, setPatientImage] = useState(
    require('../../../assets/images/patients/patient1.jpg')
  )
  //Get Patient ID from URL
  const location = useLocation()
  const uid = new URLSearchParams(location.search).get('id')

  const [patientData] = useState({
    name: 'Dina Adel',
    patientId: 'PT0016',
    location: 'Location, Lebanon',
  })

  const [patientInfo] = useState({
    phone: '+961-78931864',
    age: '30 Years',
    gender: 'Female',
  })
  const patientFiles = [
    { name: 'File 1.pdf', date: '2023-10-10', type: 'pdf' },
    { name: 'File 2.jpg', date: '2023-10-15', type: 'image' },
    { name: 'File 3.mp3', date: '2023-10-20', type: 'audio' },
    { name: 'File 4.mp4', date: '2023-10-25', type: 'video' },
  ]
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                </ol>
              </nav>

              <h2 class="breadcrumb-title">Patient Profile</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div class="row row-gap-3">
            <div className="col-12 col-md-5 col-lg-4 col-xl-3 ">
              <div className="patientprofile">
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    border: '1px solid #ededed',
                    width: '100%',
                    borderRadius: '.25rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '11px',
                      borderBottom: '1px solid #ededed',
                    }}
                  >
                    <img
                      style={{
                        maxWidth: '100%',
                        borderRadius: '50px',
                        width: '100px',
                        height: '100px',
                        alignItems: 'center',
                      }}
                      src={patientImage}
                      alt="Icon 1"
                    />
                    <p className="my-2">{patientData.name}</p>
                    <p className="mb-2">
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        Patient ID:
                      </span>{' '}
                      {patientData.patientId}{' '}
                    </p>
                    <p className="mb-3">
                      <FaMapMarkerAlt /> {patientData.location}
                    </p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Phone</span>
                      <span style={{ textAlign: 'right' }}>
                        {patientInfo.phone}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Age</span>
                      <span style={{ textAlign: 'right' }}>
                        {patientInfo.age}, {patientInfo.gender}
                      </span>
                    </div>
                  </div>
                </div>

                {/**/}

                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    border: '1px solid #ededed',
                    width: '100%',
                    marginTop: '30px',
                    borderRadius: '.25rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '11px',
                      marginTop: '15px',
                      borderBottom: '1px solid #ededed',
                    }}
                  >
                    <h4>Medical Perscription</h4>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <List>
                      {patientFiles.map((file, index) => (
                        <ListItem
                          key={index}
                          maxWidth="xs"
                          style={{
                            marginBottom: '2px',
                            shadowBox: '4',
                          }}
                        >
                          <Container maxWidth="md">
                            <Grid container>
                              <Grid
                                item
                                xs={5}
                                style={{
                                  backgroundColor: '#CCCCCC',
                                }}
                              >
                                <div style={{ paddingLeft: '10px' }}>
                                  {file.name}
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                style={{ backgroundColor: '#CCCCCC' }}
                              >
                                <div>{file.date}</div>
                              </Grid>
                              <Grid item xs={2}>
                                <MButton
                                  variant="contained"
                                  style={{
                                    height: '100%',
                                    backgroundColor: theme.colors.primary,
                                    marginBottom: '1px',
                                  }}
                                >
                                  <FaDownload />
                                </MButton>
                              </Grid>
                            </Grid>
                          </Container>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>

                {/* */}
              </div>
            </div>

            <div className="col-12 col-md-7 col-lg-8 col-xl-9">
              <div style={{ border: ' 1px solid #ededed' }}>
                <div
                  style={{
                    borderBottom: '5px solid ',
                    borderBottomColor: theme.colors.primary,
                    marginBottom: '1rem',
                    padding: '1rem',
                    textAlign: 'center',
                  }}
                >
                  <h3
                    style={{
                      padding: '0',
                      margin: '0',
                      border: '0',
                      color: theme.colors.primary,
                    }}
                  >
                    Patients' Appointments
                  </h3>
                </div>
                <div className="px-4 py-2 overflow-auto">
                  <Table striped hover style={{ borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff', border: '0' }}>
                      {appointments.map((appointment, index) => (
                        <tr key={index} className={classes.stripedRow}>
                          <td className="d-flex align-items-center">
                            {patientData.name}
                          </td>
                          <td>{appointment.date}</td>
                          <td>{appointment.time}</td>
                          <td>{appointment.date2}</td>
                          <td>
                            <span
                              className={`${classes.status} ${
                                appointment.status === 'Attended'
                                  ? classes.success
                                  : appointment.status === 'Absent'
                                  ? classes.pending
                                  : classes.danger
                              }`}
                            >
                              {appointment.status}
                            </span>
                          </td>
                          <td style={{ textAlign: 'end' }}>
                            {appointment.status === 'Absent' && (
                              <Button
                                variant="outlined"
                                className={`${classes.cancelButton} danger m-1`}
                                size="small"
                              >
                                <FaTrash /> Cancel
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default PatientProfile
