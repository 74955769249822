import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Col } from 'react-bootstrap'
import { FaRegCompass, FaUserCheck } from 'react-icons/fa'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Divider } from '@mui/material'
import { Padding } from '@mui/icons-material'

export default function GeneralInfo(props) {
  const [title, setTitle] = useState(
    props.userType === 'patient' ? 'Patient' : 'Dr.'
  )
  const [gender, setGender] = useState('male')
  const [bloodgroup, setbloodgroup] = useState('A+')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userLocation, setUserLocation] = useState('Beirut')

  // Function to fetch user's location
  const fetchUserLocation = () => {}

  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  function handleChange(event) {
    event.preventDefault()
    if (event.target.id == 'firstName') {
      setFirstName(event.target.value)
    } else if (event.target.id == 'middleName') {
      setMiddleName(event.target.value)
    } else if (event.target.id == 'lastName') {
      setLastName(event.target.value)
    } else if (event.target.id == 'location') {
      setUserLocation(event.target.value)
    } else if (event.target.id == 'title') {
      setTitle(event.target.value)
    } else if (event.target.id == 'gender') {
      setGender(event.target.value)
    } else if (event.target.id === 'email') {
      setEmail(event.target.value)
    } else if (event.target.id === 'phoneNumber') {
      setPhoneNumber(event.target.value)
    }
  }
  props.sendPersonalInfo({
    title: title,
    gender: gender,
    firstname: firstName,
    middlename: middleName,
    lastname: lastName,
    location: userLocation,
    email: email,
    phoneNumber: phoneNumber,
  })

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {
          <Grid item xs={12} sm={6}>
            <Select
              required
              labelId="demo-simple-select-label"
              id="title"
              value={title}
              label="Title"
              onChange={handleChange}
              sx={{ width: '110px' }}
            >
              <MenuItem value={'Dr.'}>Dr.</MenuItem>
              <MenuItem value={'Med.'}>Med.</MenuItem>
              <MenuItem value={'Prof.'}>Prof.</MenuItem>
            </Select>
          </Grid>
        }
        <Grid item xs={12} sm={6}>
          <Select
            required
            labelId="gender"
            id="gender"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            {' '}
            <MenuItem value={'male'}>Male</MenuItem>
            <MenuItem value={'female'}>Female</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChange}
            id="middleName"
            name="middleName"
            label="Middle name"
            fullWidth
            autoComplete="middle-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            <Form.Group as={Col} controlId="location">
              <Form.Label>
                <FaRegCompass className="icon1" /> Governate
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={'Beirut'}
                onChange={handleChange}
              >
                <option>Beirut</option>
                <option>Saida (Sidon)</option>
                <option>Nabatieh</option>
                <option>Tyre (Sour)</option>
                <option>Bekaa (Bekkaa)</option>
                <option>Zahle</option>
                <option>Byblos (Jbeil)</option>
                <option>Tripoli (Trablus)</option>
                <option>Aley</option>
                <option>Baalbek</option>
                <option>Jounieh</option>
                <option>Chouf</option>
                <option>Mount Lebanon</option>
                <option>Akkar</option>
                <option>Keserwan</option>
                <option>Metn</option>
                <option>Zgharta</option>
                <option>Bint Jbeil</option>
                <option>Hasbaya</option>
                <option>Rachaya</option>
              </Form.Control>
            </Form.Group>
          }
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            //required
            id="email"
            label="E-mail"
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            id="phoneNumber"
            label={'Phone Number'}
            fullWidth
            type="phone"
            onChange={handleChange}
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
