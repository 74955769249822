import React, { useState, useEffect, useRef } from 'react'
import theme from '../../../assets/themes'
import Sidebar from '../SideBar'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import { editProfileSettings } from '../../../api/doctor'
import { Button, Image, Form, Row, Col } from 'react-bootstrap'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { IconButton } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/FacebookRounded'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Buttonmui from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

const LoadingDialog = () => <div> loading ...</div>

const ProfileSettings = () => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/150')
  const [clinicImages, setClinicImages] = useState([])
  const [selectedPriceOption, setSelectedPriceOption] = useState(null)
  const [services, setServices] = useState([])
  const [specializations, setSpecializations] = useState([])
  const [location, setLocation] = useState('')
  let textSpecializations = useRef(null)
  let textServices = useRef(null)
  const [doctorData, setDoctorData] = useState({
    username: '',
    firstname: '',
    middlename: '',
    lastname: '',
    phone: '',
    gender: '',
    consultation_fee: '',
    profile_image: '',
    about: '',
    location_name: '',
    qualifications: [],
    experience: [],
    awards: [],
    social_media_links: [],
    specializations: [],
    services: [],
    gallery: [],
    date_of_birth: [],
  })

  const [newSpecialization, setNewSpecialization] = useState('')

  const addSpecialization = () => {
    if (newSpecialization.trim() !== '') {
      const updatedSpecializations = [
        ...doctorData.specializations,
        newSpecialization,
      ]
      setDoctorData({
        ...doctorData,
        specializations: updatedSpecializations,
      })
      setNewSpecialization('')
    }
  }

  const [newService, setNewService] = useState('')

  const addService = () => {
    if (newService.trim() !== '') {
      const updatedServices = [...doctorData.services, newService]
      setDoctorData({
        ...doctorData,
        services: updatedServices,
      })

      setNewSpecialization('')
    }
  }
  //

  const handleDataChange = (field, value) => {
    const updatedDoctorData = { ...doctorData, [field]: value }
    setDoctorData(updatedDoctorData)
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  //
  //for add more education
  const [educations, setEducations] = useState([
    { institution: '', degree: '', year: '' },
  ])
  //for add more experience
  const [experiences, setExperience] = useState([
    { Hospitalname: '', from: '', to: '', designation: '' },
  ])
  //for add more Awards
  const [awards, setAwards] = useState([{ Awards: '', Year: '' }])
  //for ad more memberships
  const [memberships, setMemberships] = useState([{ Memberships: '' }])
  // for registration addmore
  const [registration, setRegistration] = useState([
    { Registration: '', Year: '' },
  ])
  // Add More Education
  const addMoreQualification = () => {
    setDoctorData({
      ...doctorData,
      qualifications: [
        ...doctorData.qualifications,
        { institution: '', major: '', year: '' },
      ],
    })
  }
  const addMoreExperience = () => {
    setDoctorData({
      ...doctorData,
      experience: [
        ...doctorData.experience,
        { institution: '', start_year: '', end_year: '' },
      ],
    })
  }
  // ADD AWARDS FUNCTION
  const addMoreAwards = () => {
    setDoctorData({
      ...doctorData,
      awards: [...doctorData.awards, { title: '', year: '' }],
    })
  }
  // registration add more function
  const addMoreRegistration = () => {
    setRegistration([...registration, { Registration: '', Year: '' }])
  }
  // memberships add more function
  const addMoreMemberships = () => {
    setMemberships([...memberships, { Memberships: '' }])
  }
  //eductaion show less function
  const removeLastQualification = () => {
    if (doctorData.qualifications.length > 1) {
      const updatedQualifications = doctorData.qualifications.slice(0, -1)
      setDoctorData({ ...doctorData, qualifications: updatedQualifications })
    }
  }
  //experience show less
  const removeLastExperience = () => {
    if (doctorData.experience.length > 1) {
      const updatedExperiences = doctorData.experience.slice(0, -1)
      setDoctorData({ ...doctorData, experience: updatedExperiences })
    }
  }
  //awards show less
  const removeLastAwards = () => {
    if (doctorData.awards.length > 1) {
      const updatedAwards = doctorData.awards.slice(0, -1)
      setDoctorData({ ...doctorData, awards: updatedAwards })
    }
  }
  const removeLastMembership = () => {
    if (memberships.length > 1) {
      setMemberships(memberships.slice(0, -1))
    }
  }
  //registration showless
  const removeLastRegistrations = () => {
    if (registration.length > 1) {
      setRegistration(registration.slice(0, -1))
    }
  }

  // Function to fetch doctor's location
  const fetchDoctorLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        const userCoordinates = `${latitude}, ${longitude}`
        setLocation(userCoordinates) // Update the address with coordinates
      })
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  // handling changes functions

  const handleAwardsChange = (index, field, value) => {
    const updatedAwards = [...doctorData.awards]
    updatedAwards[index][field] = value
    setDoctorData({ ...doctorData, qualifications: updatedAwards })
  }
  const handleQualificationChange = (index, field, value) => {
    const updatedQualifications = [...doctorData.qualifications]
    updatedQualifications[index][field] = value

    setDoctorData({
      ...doctorData,
      qualifications: updatedQualifications,
    })
  }

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...doctorData.experience]
    updatedExperiences[index][field] = value
    setDoctorData({ ...doctorData, experience: updatedExperiences })
  }

  const handleSocialMediaChange = (platform, newURL) => {
    const updatedSocialMediaLinks = [...doctorData.social_media_links]

    const platformIndex = updatedSocialMediaLinks.findIndex(
      (link) => link.platform === platform
    )

    if (platformIndex !== -1) {
      updatedSocialMediaLinks[platformIndex].url = newURL
    } else {
      updatedSocialMediaLinks.push({ platform, url: newURL })
    }
    setDoctorData({
      ...doctorData,
      social_media_links: updatedSocialMediaLinks,
    })
  }
  const removeService = (serviceToRemove) => {
    const updatedServices = doctorData.services.filter(
      (service) => service !== serviceToRemove
    )
    setDoctorData({
      ...doctorData,
      services: updatedServices,
    })
  }
  const removeSpecializations = (specializationToRemove) => {
    const updatedSpecializations = doctorData.specializations.filter(
      (special) => special !== specializationToRemove
    )
    setDoctorData({
      ...doctorData,
      specializations: updatedSpecializations,
    })
  }
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0])
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }
  const handleClinicImagesChange = (e) => {
    // Added for multiple clinic images
    // if (e.target.files) {
    //   let fileList = Array.from(e.target.files)
    //   setClinicImages(fileList.map((file) => URL.createObjectURL(file)))
    // }
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader()
      reader.onload = function (e) {
        setImageUrl(e.target.result)
      }
      reader.readAsDataURL(e.target.files[0])

      // Update the profile_image in doctorData
      setDoctorData({ ...doctorData, profile_image: e.target.files[0] })
    }
  }
  const handlePriceOptionChange = (e) => {
    // setSelectedPriceOption(e.target.value)
    const value = e.target.value
    setSelectedPriceOption(value)
    if (value === 'free') {
      setDoctorData({
        ...doctorData,
        consultation_fee: 0,
      })
    } else if (value === 'custom') {
      setDoctorData({
        ...doctorData,
        consultation_fee: '',
      })
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    // handle form submission here...
    setLoading(true)
    try {
      const response = await editProfileSettings(doctorData)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
      setLoading(false)
    } catch (error) {
      console.error('Error updating doctor data:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    const savedDoctorData = JSON.parse(localStorage.getItem('doctor'))
    if (savedDoctorData) {
      setDoctorData(savedDoctorData)
    }
  }, [])

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/Dashboard">Home</a>
                  </li>

                  <li class="breadcrumb-item active" aria-current="page">
                    Profile Settings
                  </li>
                </ol>
              </nav>

              <h2 class="breadcrumb-title">Profile Settings Page</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <>
                <Form
                  onSubmit={handleFormSubmit}
                  style={{
                    backgroundColor: '#fff',
                    margin: 10,
                    borderRadius: 20,
                  }}
                >
                  {/* personal information section */}
                  <div className="mb-3" style={{ padding: 20 }}>
                    <Row className="px-3">
                      <div className=" px-3 mb-3">
                        {/* edit md to lg  */}
                        <div lg={12}>
                          <Avatar
                            alt="Remy Sharp"
                            src={
                              doctorData.profile_image
                                ? doctorData.profile_image
                                : imageUrl
                            }
                            sx={{
                              width: 120,
                              height: 120,
                              marginBottom: '20px',
                            }}
                          />

                          {/* <Form.Group>
                            <Form.Label>Upload Profile Picture</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleImageChange}
                              accept="image/*"
                            />
                          </Form.Group> */}
                          <Buttonmui
                            style={{
                              backgroundColor: '#15558d',
                            }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Profile Picture
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleImageChange}
                              accept="image/*"
                            />
                          </Buttonmui>
                        </div>
                      </div>
                      {/* edit md to lg  */}
                      <Col lg={12}>
                        <Row>
                          {/* edit md to lg  */}
                          <Col lg={6}>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                type="text"
                                value={doctorData.username}
                                disabled
                              />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Middle Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={doctorData.middlename}
                                onChange={(e) =>
                                  handleDataChange('middlename', e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="tel"
                                value={doctorData.phone}
                                onChange={(e) =>
                                  handleDataChange('phone', e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          {/* edit md to lg  */}
                          <Col lg={6}>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={doctorData.firstname}
                                onChange={(e) =>
                                  handleDataChange('firstname', e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                value={doctorData.lastname}
                                onChange={(e) =>
                                  handleDataChange('lastname', e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Email </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="To Be Added"
                                disabled
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          {/* edit md to lg  */}
                          <Col lg={6}>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  doctorData.date_of_birth != null
                                    ? `${doctorData.date_of_birth.year}/${doctorData.date_of_birth.month}/${doctorData.date_of_birth.day}`
                                    : '01/01/1970'
                                }
                                onChange={(e) => {
                                  const [year, month, day] =
                                    e.target.value.split('/')
                                  const dateOfBirth = {
                                    year: year ? parseInt(year) : '',
                                    month: month ? parseInt(month) : '',
                                    day: day ? parseInt(day) : '',
                                  }
                                  handleDataChange('date_of_birth', dateOfBirth)
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {/* edit md to lg  */}
                          <Col lg={6}>
                            <Form.Group style={{ marginBottom: '10px' }}>
                              <Form.Label>Gender</Form.Label>
                              <Form.Control
                                as="select"
                                defaultValue="Choose..."
                                value={doctorData.gender}
                                onChange={(e) =>
                                  handleDataChange('gender', e.target.value)
                                }
                              >
                                <option disabled>Choose...</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* personal information section */}

                  {/* about section  */}
                  <div
                    className="mx-3"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  >
                    <h2 className="mb-3">About Me</h2>

                    <Form.Group>
                      {/* <Form.Label>Biography</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your biography here..."
                        value={doctorData.about}
                        onChange={(e) =>
                          handleDataChange('about', e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  {/* about section  */}

                  {/* clinic section  */}
                  <div
                    className="mb-3 mx-3"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  >
                    <h2 className="mb-3">Clinic Details</h2>
                    <Row>
                      {/* edit md to lg  */}
                      <Col lg={6} style={{ marginBottom: '20px' }}>
                        <Form.Group>
                          <Form.Label>Clinic Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={`Dr.${doctorData.firstname} ${doctorData.lastname}'s Clinic`}
                          />
                        </Form.Group>
                      </Col>
                      {/* edit md to lg  */}
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Clinic Address</Form.Label>
                          <Form.Control
                            type="text"
                            onClick={fetchDoctorLocation}
                            variant="standard"
                            value={doctorData.location_name}
                            onChange={(e) =>
                              handleDataChange('location_name', e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {/* edit md to lg  */}
                      <Col lg={12}>
                        {/* <Form.Group>
                          <Form.Label>Upload Clinic Images</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleClinicImagesChange}
                            accept="image/*"
                            multiple
                          />
                        </Form.Group> */}
                        <Buttonmui
                          style={{
                            backgroundColor: '#15558d',
                            marginBottom: '10px',
                          }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Clinic Images
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleClinicImagesChange}
                            accept="image/*"
                            multiple
                          />
                        </Buttonmui>

                        <Row>
                          {doctorData.gallery.map((image, index) => (
                            // {/* edit md to lg  */}
                            <Col lg={3} key={index}>
                              <Image src={image} thumbnail />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* clinic section  */}
                  <div className="mb-3" style={{ padding: 20 }}>
                    <h2 className="mb-3">Price Details</h2>
                    <Form.Group>
                      <Form.Label>Choose Price</Form.Label>
                      <Form.Check
                        type="radio"
                        label="Free"
                        name="priceOptions"
                        id="free"
                        checked={
                          doctorData.consultation_fee === 0 ? 'free' : null
                        }
                        value="free"
                        onChange={handlePriceOptionChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Custom Price (per hour)"
                        name="priceOptions"
                        id="customPrice"
                        value="custom"
                        checked={
                          selectedPriceOption === 'custom' ||
                          doctorData.consultation_fee !== 0
                        }
                        onChange={handlePriceOptionChange}
                      />
                      {(selectedPriceOption === 'custom' ||
                        doctorData.consultation_fee !== 0) && (
                        <Form.Control
                          type="number"
                          placeholder="Enter custom price per hour"
                          value={doctorData.consultation_fee}
                          onChange={(e) =>
                            handleDataChange('consultation_fee', e.target.value)
                          }
                          className="my-2"
                        />
                      )}
                    </Form.Group>
                    <div style={{ borderBottom: '3px solid #15558d' }}>
                      <h2 className="mb-3 mt-5">
                        Services and Specializations
                      </h2>

                      <Form.Label>Services</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          marginBottom: '30px',
                        }}
                      >
                        <div className=" d-inline-flex p-2">
                          {doctorData.services.map((service, index) => (
                            <div className="p-2 " key={index}>
                              <Chip
                                deleteIcon={
                                  <CancelIcon style={{ color: 'white' }} />
                                }
                                onDelete={() => removeService(service)}
                                label={service}
                                style={{
                                  Width: '100px',
                                  backgroundColor: '#15558d',
                                  color: 'white',
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <Form.Group
                          className="mb-2 px-3 d-inline-flex"
                          style={{ minWidth: '370px' }}
                        >
                          <TextField
                            type="text"
                            placeholder={
                              doctorData.services === null
                                ? 'Type and press , to add a service'
                                : ''
                            }
                            inputRef={textServices}
                            style={{ border: 'none' }}
                            variant="standard"
                            onChange={(e) => setNewService(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === ',') {
                                addService()
                                textServices.current.value = ''
                              }
                            }}
                          />
                        </Form.Group>
                      </div>

                      <Form.Label>Specializations</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          marginBottom: '30px',
                        }}
                      >
                        <div className="d-inline-flex p-2">
                          {doctorData.specializations.map((special, index) => (
                            <div className="p-2" key={index}>
                              <Chip
                                deleteIcon={
                                  <CancelIcon style={{ color: 'white' }} />
                                }
                                onDelete={() => removeSpecializations(special)}
                                label={special}
                                style={{
                                  Width: '100px',
                                  backgroundColor: '#15558d',
                                  color: 'white',
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <Form.Group
                          className="mb-2 px-3 d-inline-flex"
                          style={{ minWidth: '370px' }}
                        >
                          <TextField
                            type="text"
                            placeholder={
                              doctorData.specializations === null
                                ? 'Type and press , to add a specialization'
                                : ''
                            }
                            inputRef={textSpecializations}
                            style={{ border: 'none' }}
                            variant="standard"
                            onChange={(e) =>
                              setNewSpecialization(e.target.value)
                            }
                            onKeyPress={(e) => {
                              if (e.key === ',') {
                                addSpecialization()
                                textSpecializations.current.value = ''
                              }
                            }}
                          />
                        </Form.Group>
                      </div>

                      {/* services section */}
                    </div>
                  </div>

                  {/* services section */}
                  {/* education */}
                  <div className="mb-3" style={{ padding: 20 }}>
                    <h2 className="mb-3">Education</h2>
                    {doctorData.qualifications.map((qualification, index) => (
                      <Row key={index} style={{ marginBottom: '10px' }}>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Institution</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Institution"
                              value={qualification.institution}
                              onChange={(e) =>
                                handleQualificationChange(
                                  index,
                                  'institution',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Degree</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Degree"
                              value={qualification.major}
                              onChange={(e) =>
                                handleQualificationChange(
                                  index,
                                  'major',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Year"
                              value={qualification.end_year}
                              onChange={(e) =>
                                handleQualificationChange(
                                  index,
                                  'end_year',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreQualification}>
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton
                      color="secondary"
                      onClick={removeLastQualification}
                    >
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  {/* education */}
                  {/* experience */}
                  <div
                    className="mb-3 mx-3"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  >
                    <h2 className="mb-3">Experience</h2>
                    {doctorData.experience.map((experience, index) => (
                      <Row key={index} style={{ marginBottom: '10px' }}>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Hospitalname</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Hospitalname"
                              value={experience.institution}
                              onChange={(e) =>
                                handleExperienceChange(
                                  index,
                                  'institution',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>From</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={experience.start_year}
                              onChange={(e) =>
                                handleExperienceChange(
                                  index,
                                  'start_year',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>to</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              value={experience.end_year}
                              onChange={(e) =>
                                handleExperienceChange(
                                  index,
                                  'end_year',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreExperience}>
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton
                      color="secondary"
                      onClick={removeLastExperience}
                    >
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  {/* experience */}
                  {/* awards */}
                  <div
                    className="mb-3 mx-3"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  >
                    <h2 className="mb-3">Awards</h2>
                    {doctorData.awards.map((award, index) => (
                      <Row key={index} style={{ marginBottom: '10px' }}>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Awards</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Hospitalname"
                              value={award.title}
                              onChange={(e) =>
                                handleAwardsChange(
                                  index,
                                  'title',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {/* edit md to lg  */}
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control
                              type="number"
                              value={award.year}
                              onChange={(e) =>
                                handleAwardsChange(
                                  index,
                                  'year',
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreAwards}>
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastAwards}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  {/* awards */}
                  {/* membership */}
                  {/* <div
                    className="mb-3 mx-3"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  > */}
                  {/* <h2 className="mb-3">Memberships</h2>
                    {memberships.map((Memberships, index) => (
                      <Row key={index}> */}
                  {/* edit md to lg  */}
                  {/* <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Memberships</Form.Label>
                            <Form.Control type="text" placeholder=" " />
                          </Form.Group>
                        </Col>
                      </Row> */}
                  {/* ))} */}
                  {/* <IconButton color="primary" onClick={addMoreMemberships}>
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton
                      color="secondary"
                      onClick={removeLastMembership}
                    > */}
                  {/* <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div> */}
                  {/* membership */}
                  {/* registration */}
                  {/* <div
                    className="mb-2"
                    style={{ padding: 20, borderBottom: '3px solid #15558d' }}
                  > */}
                  {/* <h2 className="mb-3">Registration</h2>
                    {registration.map((awards, index) => (
                      <Row key={index}> */}
                  {/* edit md to lg  */}
                  {/* <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Registration</Form.Label>
                            <Form.Control type="text" placeholder=" " />
                          </Form.Group>
                        </Col> */}
                  {/* edit md to lg  */}
                  {/* <Col lg={4}> */}
                  {/* <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="number" placeholder="" />
                          </Form.Group>
                        </Col>
                      </Row> */}
                  {/* ))} */}
                  {/* <IconButton color="primary" onClick={addMoreRegistration}>
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More */}
                  {/* <IconButton
                      color="secondary"
                      onClick={removeLastRegistrations}
                    >
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less */}
                  {/* </div> */}

                  <div className="mb-3 mx-3" style={{ padding: 10 }}>
                    {['Facebook', 'Instagram', 'Linkedin', 'X'].map(
                      (platform) => {
                        const socialMediaLink =
                          doctorData.social_media_links.find(
                            (link) => link.platform === platform
                          )
                        const icons = () => {
                          if (platform === 'Facebook') {
                            return (
                              <FacebookIcon
                                style={{
                                  color: 'white',
                                  backgroundColor: '#212529',
                                  marginLeft: '7px',
                                  marginRight: '7px',
                                  borderRadius: '4px',
                                }}
                              />
                            )
                          } else if (platform === 'Instagram') {
                            return (
                              <InstagramIcon
                                style={{
                                  color: 'white',
                                  backgroundColor: '#212529',
                                  marginLeft: '7px',
                                  marginRight: '7px',
                                  borderRadius: '4px',
                                }}
                              />
                            )
                          } else if (platform === 'Linkedin') {
                            return (
                              <LinkedInIcon
                                style={{ width: '35px', height: '35px' }}
                              />
                            )
                          } else if (platform === 'X') {
                            return (
                              <svg
                                style={{
                                  backgroundColor: '#212529',
                                  color: 'white',
                                  padding: '3px',
                                  marginLeft: '7px',
                                  marginRight: '7px',
                                  borderRadius: '4px',
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-twitter-x"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                              </svg>
                            )
                          } else {
                          }
                        }
                        return (
                          <Form.Group key={platform}>
                            <Form.Label>
                              {icons()} {platform} URL
                            </Form.Label>
                            <Form.Control
                              className="link mb-3"
                              type="url"
                              value={socialMediaLink ? socialMediaLink.url : ''}
                              onChange={(e) =>
                                handleSocialMediaChange(
                                  platform,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        )
                      }
                    )}
                  </div>

                  <div className=" p-3">
                    <Button
                      variant="primary"
                      //center this button
                      className="mx-auto d-block"
                      type="submit"
                      style={{ width: '40%', backgroundColor: '#15558d' }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
                {loading && <LoadingDialog />}
              </>
            </div>
          </div>
          <br></br>
        </div>
      </div>

      <Footer />
    </div>
  )
}
export default ProfileSettings
