import React from 'react'
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap'
import { BsEmojiSmile } from 'react-icons/bs'
import { FaRegCompass, FaUserCheck } from 'react-icons/fa'
import doctorBanner from '../../assets/images/doctor-banner.png'
import HomeHeader from './HomeHeader'
import Footer from '../HomeFooter'
import styled from '@emotion/styled'
import governorates from '../../utils/governorates'

const StyledH3 = styled('h3')({
  fontweight: 'bolder',
  color: '#15558d',
})

const Home = () => {
  return (
    <div style={{ backgroundColor: '#E1F3FFff', paddingTop: '100px' }}>
      <HomeHeader />

      <Container>
        <Row className="d-lg-flex d-block">
          <Col>
            <Form>
              <Row className=" justify-content-center justify-content-md-start">
                <StyledH3>Search Doctor,</StyledH3>
              </Row>
              <Row className=" justify-content-center justify-content-md-start">
                <StyledH3>Make an Appointment</StyledH3>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="location">
                  <Form.Label>
                    <FaRegCompass className="icon1" /> Location
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select Governorate</option>
                    {governorates.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="gender">
                  <Form.Label>
                    <BsEmojiSmile className="icon2" /> Gender
                  </Form.Label>
                  <Form.Control as="select">
                    <option>Male</option>
                    <option>Female</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="department">
                  <Form.Label>
                    <FaUserCheck className="icon3" /> Speciality
                  </Form.Label>
                  <Form.Control as="select">
                    <option>Anesthesiology</option>
                    <option>Cardiology</option>
                    <option>Dermatology</option>
                    <option>Endocrinology</option>
                    <option>Gastroenterology</option>
                    <option>Geriatrics</option>
                    <option>Hematology</option>
                    <option>Infectious Diseases</option>
                    <option>Internal Medicine</option>
                    <option>Nephrology</option>
                    <option>Neurology</option>
                    <option>Obstetrics and Gynecology</option>
                    <option>Oncology</option>
                    <option>Ophthalmology</option>
                    <option>Orthopedics</option>
                    <option>Otorhinolaryngology (ENT)</option>
                    <option>Pediatrics</option>
                    <option>Psychiatry</option>
                    <option>Pulmonology</option>
                    <option>Radiology</option>
                    <option>Rheumatology</option>
                    <option>General Surgery</option>
                    <option>Cardiothoracic Surgery</option>
                    <option>Colorectal Surgery</option>
                    <option>Neurosurgery</option>
                    <option>Plastic and Reconstructive Surgery</option>
                    <option>Transplant Surgery</option>
                    <option>Urology</option>
                    <option>Vascular Surgery</option>
                    <option>Emergency Medicine</option>
                    <option>Allergy and Immunology</option>
                    <option>Critical Care Medicine</option>
                    <option>Physical Medicine and Rehabilitation (PM&R)</option>
                    <option>Sports Medicine</option>
                    <option>Pathology</option>
                    <option>Nuclear Medicine</option>
                    <option>Genetics</option>
                    <option>Occupational Medicine</option>
                    <option>Nutrition and Dietetics</option>
                    <option>Palliative Care</option>
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className=" justify-content-center px-5 px-md-0">
                <Button
                  type="submit"
                  href="/SearchDoctor"
                  style={{
                    background: '#15558d',
                    borderColor: '#15558d',
                    color: '#fff',
                    width: '100%',
                    borderRadius: '20px',
                    textAlign: 'center',
                    paddingTop: '10px',
                    alignItems: 'center',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  FIND A DOCTOR
                </Button>
              </Row>
            </Form>
          </Col>
          <Col className=" justify-content-center">
            <Image
              src={doctorBanner}
              alt="Doctor"
              style={{ width: '90%', marginBottom: '0px' }}
            />
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  )
}

export default Home
