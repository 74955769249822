import React from 'react'
import '../../../App.css'
import Header from '../Header'
import Sidebar from '../../Patients/SideBar'
import Footer from '../../Footer'
//
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

//
const localizer = momentLocalizer(moment)
const events = [
  {
    title: 'Dr. John',
    start: new Date(2024, 1, 12, 10, 0),
    end: new Date(2024, 1, 12, 11, 30),
  },
  {
    title: 'Blood Test - Dr. Faaour',
    start: new Date(2024, 1, 13, 9, 0),
    end: new Date(2024, 1, 13, 11, 0),
  },
  {
    title: 'Dr. Saleh',
    start: new Date(2024, 1, 22, 10, 0),
    end: new Date(2024, 1, 22, 11, 30),
  },
  {
    title: 'Dr. Bob',
    start: new Date(2024, 1, 7, 10, 0),
    end: new Date(2024, 1, 7, 10, 30),
  },
  {
    title: 'Dr. John',
    start: new Date(2024, 2, 12, 10, 0),
    end: new Date(2024, 2, 12, 11, 30),
  },
  {
    title: 'Blood Test - Dr. Faaour',
    start: new Date(2024, 2, 23, 9, 0),
    end: new Date(2024, 2, 13, 11, 0),
  },
  {
    title: 'Dr. Saleh',
    start: new Date(2024, 2, 15, 10, 0),
    end: new Date(2024, 2, 15, 11, 30),
  },
  {
    title: 'Dr. Bob',
    start: new Date(2024, 3, 27, 7, 0),
    end: new Date(2024, 3, 17, 10, 40),
  },
  {
    title: 'Dr. John',
    start: new Date(2024, 3, 12, 10, 0),
    end: new Date(2024, 3, 12, 11, 30),
  },
  {
    title: 'Blood Test - Dr. Faaour',
    start: new Date(2024, 3, 13, 9, 0),
    end: new Date(2024, 3, 13, 11, 0),
  },
  {
    title: 'Dr. Saleh',
    start: new Date(2024, 3, 25, 10, 0),
    end: new Date(2024, 3, 15, 11, 30),
  },
  {
    title: 'Dr. Bob',
    start: new Date(2024, 3, 17, 7, 0),
    end: new Date(2024, 3, 17, 10, 40),
  },
]
const PatientCalendar = () => {
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    Calendar
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">My Calendar</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div class="content">
        <div class="container-fluid">
          {/* Page Content Should be Added here inside this div */}
          <div className="row">
            {/* Add Here your Page Content (Table,Image,...) */}
            {/*  */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div className="col-lg-8 col-xl-9 bg-white p-3">
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                // titleAccessor={`${e.title} `}
                titleAccessor={function (e) {
                  return `${e.start.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })} ${e.title}`
                }}
                views={['month', 'week', 'day']}
                style={{ height: 500 }}
                eventPropGetter={(event) => {
                  const backgroundColor = '#15558d'
                  return { style: { backgroundColor } }
                }}
              />
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}

export default PatientCalendar
