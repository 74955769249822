import React, { useEffect, useState } from 'react'
import { Button, Table, Card } from 'react-bootstrap'
import Sidebar from '../SideBar'
import '../../../App.css'
import Theme from '../../../assets/themes'
import Header from '../Header'
import Footer from '../../Footer'
import { FaEye } from 'react-icons/fa'
import { Typography } from '@mui/material'
import { fetchAppointment } from '../../../api/patient'
import banner from '../../../assets/images/banner-img.png'
const PatientDashboard = () => {
  const [appointments, setAppointments] = useState([])
  const generateButton = (onClickHandler) => (
    <div style={{ display: 'inline-block', marginRight: '5px' }}>
      <Button
        variant="primary"
        style={{
          width: '80px',
          height: '31px',
          padding: '5px 8.58px 5px 9px',
          border: '1px',
          borderRadius: '3.2px',
          background: 'rgb(135, 206, 235)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClickHandler}
      >
        <FaEye style={{ marginRight: '4px' }} />
        <Typography sx={{ fontWeight: 'bold' }}>View</Typography>
      </Button>
    </div>
  )
  useEffect(() => {
    async function fetchApp() {
      try {
        const appointment = await fetchAppointment()
        setAppointments(appointment)
      } catch (error) {
        console.log(error)
      }
    }
    fetchApp()
  }, [])
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 className="breadcrumb-title">Patient Dashboard</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      {/* Body Content */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            {/* Sidebar Component */}
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            {/* Sidebar Component */}
            {/* Page Content Should be Added here inside this div */}
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card style={{ padding: '20px' }}>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <h3 style={{ color: 'black' }}>My Appointments</h3>
                  <hr
                    style={{
                      borderBottom: '5px solid #15558D',
                      textAlign: 'center',
                    }}
                  />
                </div>
                <div className="px-2 py-1 overflow-auto">
                  <Table>
                    <thead>
                      <tr style={{ fontWeight: 'bold' }}>
                        <th>Doctor Name</th>
                        <th>Phone Number</th>
                        <th>Appt Date</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointments.map((appointment) => {
                        const apptDate = appointment.appointment_date
                        const dateObj = new Date(apptDate)
                        const date = dateObj.toLocaleDateString()
                        const time = appointment.start_hour
                        return (
                          <tr key={appointment.id}>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={
                                    appointment.doctor_id.profile_image == null
                                      ? banner
                                      : appointment.doctor_id.profile_image
                                  }
                                  alt={`Doctor Avatar `}
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    marginRight: '10px',
                                  }}
                                />
                                {appointment.doctor_id.firstname == null
                                  ? 'static'
                                  : appointment.doctor_id.firstname}{' '}
                                {appointment.doctor_id.lastname == null
                                  ? 'static'
                                  : appointment.doctor_id.lastname}
                              </div>
                            </td>
                            <td>{appointment.patient_phone}</td>
                            <td>
                              {date}
                              <br />
                              <span
                                style={{
                                  color: Theme.colors.secondary,
                                  fontWeight: 'bold',
                                }}
                              >
                                {time}
                              </span>
                            </td>
                            <td>{appointment.patient_governorate}</td>
                            <td>
                              <Typography
                                sx={{
                                  color: '#26AF48',
                                  border: '1px',
                                  background: 'rgba(15, 183, 107, 0.12)',
                                  borderRadius: '160px',
                                  padding: '1.81px 7.17px 3.07px 5.75px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  fontWeight: 'bold',
                                  marginLeft: '-10px',
                                }}
                              >
                                Booked
                              </Typography>
                            </td>
                            <td>{generateButton(() => {})}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  )
}
export default PatientDashboard
